<div class="modal-backdrop" (click)="closeModal()"></div>
<div class="modal-container">
  <div class="modal-content">
    <div class="heading">
      <h2>Join BJS Academy</h2>
      <hr class="hr hr--color hr--lg">
    </div>

    <p  *ngIf="!success">Please complete this form to join BJS Academy and receive our latest news and updates.</p>
    <div *ngIf="warning" class="alert alert-warning d-flex align-items-center" role="alert">
      <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning-icon"></fa-icon>
      <div>{{ serverError }}</div>
    </div>
    
    <div *ngIf="failed" class="alert alert-danger d-flex align-items-center" role="alert">
      <fa-icon [icon]="['fas', 'times-circle']" class="failed-icon"></fa-icon>
      <div>{{ serverError }}</div>
    </div>
    
    <form [formGroup]="modalForm" (ngSubmit)="onSubmit()"  *ngIf="!success">
      <div class="form-row">
        <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text" id="FNAME" formControlName="FNAME" placeholder="Enter your first name*"
            class="form-control">
          <div *ngIf="modalForm.get('FNAME')?.invalid && (modalForm.get('FNAME')?.touched || submitted)"
            class="error-message">
            First Name is required.
          </div>
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text" id="LNAME" formControlName="LNAME" placeholder="Enter your last name*"
            class="form-control">
          <div *ngIf="modalForm.get('LNAME')?.invalid && (modalForm.get('LNAME')?.touched || submitted)"
            class="error-message">
            Last Name is required.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="EMAIL" formControlName="EMAIL" placeholder="Enter your email*" class="form-control">
        <div *ngIf="modalForm.get('EMAIL')?.invalid && (modalForm.get('EMAIL')?.touched || submitted)" class="error-message">
          <span *ngIf="modalForm.get('EMAIL')?.errors?.['required']">A valid email is required.</span>
          <span *ngIf="modalForm.get('EMAIL')?.errors?.['emailTaken']">This email has already been used.</span>
        </div>
        
      </div>


      <div class="form-group">
        <label for="socialMedia">Social Media Handle</label>
        <input type="text" id="MERGE5" formControlName="MERGE5" placeholder="Enter your social media handle"
          class="form-control">
      </div>
      <div class="form-group">
        <label for="country">Country of Residence</label>
        <select id="MMERGE3" formControlName="MMERGE3" class="form-select">
          <option value="" disabled selected class="placeholder">Country of Residence*</option>
          <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
        </select>
        <div *ngIf="modalForm.get('MMERGE3')?.invalid && (modalForm.get('MMERGE3')?.touched || submitted)"
          class="error-message">
          Country of Residence is required.
        </div>
      </div>

      <div class="form-group">
        <label for="surgicalSpecialty">Surgical Specialty</label>
        <select id="MMERGE6" formControlName="MMERGE6" class="form-select">
          <option value="" disabled selected class="placeholder">Surgical Specialty*</option>
          <option *ngFor="let specialty of surgicalSpecialties" [value]="specialty">{{ specialty }}</option>
        </select>
        <div
          *ngIf="modalForm.get('MMERGE6')?.invalid && (modalForm.get('MMERGE6')?.touched || submitted)"
          class="error-message">
          Surgical Specialty is required.
        </div>
      </div>

      <!-- <div class="modal-actions d-flex flex-column">
        <button type="button" class="btn-submit" (click)="onSubmit()" [disabled]="isLoading">
          <fa-icon *ngIf="isLoading" [icon]="['fas', 'spinner']" [ngClass]="{ 'fa-spin': isLoading }"></fa-icon>
          <span *ngIf="!isLoading">Submit</span>
        </button>
        
        
        
        <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
      </div> -->
      <div class="modal-actions d-flex flex-column">
        <button
          type="button"
          class="btn-submit"
          (click)="onSubmit()"
          [disabled]="isLoading"
        >
          <fa-icon *ngIf="isLoading" [icon]="['fas', 'spinner']" [ngClass]="{ 'fa-spin': isLoading }"></fa-icon>
          <span *ngIf="!isLoading">Submit</span>
          <!-- <span *ngIf="isLoading">Submitting...</span> -->
        </button>
      
        <button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
      </div>
      
    </form>
    <div
    class="modal-content-custom d-flex flex-column align-items-center justify-content-center text-center"
    *ngIf="success; " role="alert"
  >
    <div class="icon-container">
      <fa-icon [icon]="['fas', 'check-circle']" class="success-icon"></fa-icon>
    </div>
    <div class="success-msg mt-2">Form submitted successfully</div>
  </div>
  
 
  
  

</div>