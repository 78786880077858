import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class MainHomeService {

  constructor(private http: HttpClient) { }
    getAllThemeSettings(journal_id: string) {
      const param = {
          journal_id: journal_id
      };

      return this.http.get<any>(env.siteApiUrl + "web/gettheme_logobanner?journal_id=" + param.journal_id);
  }
  getMenuDetails(){
    return this.http.get<any>(env.siteApiUrl + "web/menus");
  }
  getContentType(){
    return this.http.get<any>(env.siteApiUrl + "web/content-type");

  }
  addBjsJoinAcademy(data:any){
 
  return this.http.post<any>(env.siteApiUrl + "web/add-bjs-join-academy",data);

  }
  checkEmailAvailability(email: string) {
    return this.http.post<any>(env.siteApiUrl + "web/check-email-availability", { email: email });
  }
  subscribe(data:any){
    return this.http.post<any>(env.siteApiUrl + "subscribe",data);
  }
  
}
