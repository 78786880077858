<form [formGroup]="searchForm">
	<div class="row " #searchFormDiv>
		<div
			class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 ps-0 pe-xxxl-1 pe-xl-1 pe-lg-1 pe-md-1 pe-sm-1 pe-xs-1">
			<!-- <div class="input-group input-group-lg position-relative">
        <span class="input-group-text search-text-box" id="basic-addon1">
          <fa-icon [icon]="['fas','magnifying-glass']" class="search-icon"></fa-icon>
        </span>
        <input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
               aria-describedby="basic-addon1" formControlName="searchValue" (keyup)="searchArticles()">
        <div class="spinner-container">
          <div *ngIf="ShowTextLoader" class="spinner-border float-right" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div>
            <button *ngIf="search" type="submit" class="btn btn-primary btn-md clear-button mt-0" (click)="clearSearch()">
              <fa-icon [icon]="['fas', 'eraser']" class="search-button-icon"></fa-icon>
              <span class="d-xs-none d-sm-none d-md-inline custom-search">clear</span>
            </button>
          </div>
      
        </div>
      </div> -->
			<div class="input-group input-group-lg position-relative">
				<span class="input-group-text search-text-box" id="basic-addon1">
					<fa-icon [icon]="['fas','magnifying-glass']" class="search-icon"></fa-icon>
				</span>
				<!-- <input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
               aria-describedby="basic-addon1" formControlName="searchValue" (input)="searchArticles($event)" (keyup)="searchArticles($event)" (keyup.enter)="onEnterPressed($event)" > -->
				<input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
					aria-describedby="basic-addon1" formControlName="searchValue" (keyup.enter)="onEnterPressed($event)"
					(input)="showSearchSuggestions($event)" (click)="onTextboxClicked()" />
				<!-- Loader -->

				<div *ngIf="ShowTextLoader==true" class="spinner-container" [ngStyle]="{'right':clearBtnWidth+'px'}">
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>

				<!-- Clear Button -->
				<button *ngIf="search" type="button" class="clear-button" (click)="clearSearch()" #clearBtn>
					<fa-icon [icon]="['fas', 'eraser']" class="search-button-icon "></fa-icon>
					<span clas="clear-button-icon">Clear</span>
				</button>
				<!-- <div class="position-relative d-inline-block ps-3 pt-3"> -->

				<!-- </div> -->
			</div>



			<div *ngIf="showSearchSuggesion && searchResults.length > 0" class="search-result-section"
				[ngStyle]="{'width': resultBoxwidth + 'px'}">
				<div>
					<ul class="p-0">
						<li *ngFor="let result of searchResults; let isLast = last" class="search-result-li">
							<div class="d-flex flex-column custom-margin">
								<p class="ml-3 search-title"
									(click)="navigateToArticle(result.article_link,result.category_heading.id)"
									[innerHTML]="highlightMatch(result.article_title, searchForm.get('searchValue')?.value)">
								</p>
								<p class="ml-3 search-author" [ngStyle]="{'font-size': '12px'}"
									[innerHTML]="highlightMatch(formatAuthors(result.authors), searchForm.get('searchValue')?.value)">
								</p>
								<p class="ml-3 search-pub-date">
									<fa-icon [icon]="['fas','calendar-days']" class="icon-date pe-2"></fa-icon>
									<span [innerHTML]="result.published_online"></span>
								</p>
								<div class="ml-3 card-tag">
									<a href="#" class="tag-content badge">{{ result.surgical_speciality }}</a>
									<a href="#" class="tag-content badge">{{ result.content_type }}</a>
								</div>
							</div>
							<!-- <hr *ngIf="!isLast" class="search-result-divider"> -->
						</li>
					</ul>
				</div>
			</div>
			<div *ngIf="showSearchSuggesion && !ShowTextLoader && !isInitialLoad && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length > 3"
				class="search-result-section" [ngStyle]="{'width': resultBoxwidth + 'px'}">
				<div>
					<ul class="p-0">
						<li class="search-result-li">
							<!-- <div class="d-flex justify-content-center">
								<p class="mt-3">No results found</p>
							</div> -->
							<div
								style="display: flex; flex-direction: column; align-items: center; padding-bottom: 5px;">
								<img src="no_data_1.webp" alt="" class="no-data-found-img">
								<h2 class="card-title text-head-size">No articles found</h2>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- <div *ngIf="!ShowTextLoader && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length === 0" class="search-result-section">
        <ul class="p-0">
          <li class="search-result-li">
            <div class="d-flex justify-content-center">
              <p class="mt-3">No results found</p>
            </div>
          </li>
        </ul>
      </div> -->


			<!-- <div *ngIf="!ShowTextLoader && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length ==0" class="search-result-section">
        <ul class="p-0">
          <li class="search-result-li">
            <div class="d-flex justify-content-center">
              <p class="mt-3">No results found</p>
            </div>
          </li>
        </ul>
      </div> -->
		</div>

		<div
			class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 pe-0 ps-xxl-2 ps-xl-2 ps-lg-2 ps-md-1 ps-sm-1 ps-xs-1 d-xs-none">
			<div class="d-flex flex-row justify-content-between align-items-center">
				<!-- Search Button -->
				<button type="submit" class="btn btn-primary btn-lg search-button mt-0"
					(click)="load_filter = !load_filter;setPaddingToFilterBox();toggleFilter()" >
					<fa-icon [icon]="['fas', 'filter']" class="search-filter-icon"></fa-icon>
					<span class="d-xs-none d-sm-none d-md-none d-lg-inline custom-search ps-1">Filter by</span>
					<span *ngIf="filter_total_count > 0" class="badge bg-secondary position-absolute filter-count">
						{{ filter_total_count }}
					</span>
				</button>
			</div>
		</div>

		<!-- Filter Section -->
	
		<div *ngIf="load_filter" class="backdrop"  >
			<div class="filter custom-select-scroll" [ngClass]="{ 'open': load_filter }" *ngIf="load_filter" #filterContainer>
				<div class="card filter-section" #filterCard>
					<div class="card-body" *ngIf="surgicalView || contentTypeView">
						<div class="row">
							<div class=" col-lg-10 col-sm-10 col-xs-10">
								<div class="input-group input-group-lg position-relative">
									<span class="input-group-text search-text-box" id="basic-addon1">
										<fa-icon [icon]="['fas','magnifying-glass']" class="search-icon"></fa-icon>
									</span>
									<!-- <input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
								   aria-describedby="basic-addon1" formControlName="searchValue" (input)="searchArticles($event)" (keyup)="searchArticles($event)" (keyup.enter)="onEnterPressed($event)" > -->
									<input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
										aria-describedby="basic-addon1" formControlName="searchValue" (keyup.enter)="onEnterPressed($event)"
										(input)="showSearchSuggestions($event)" (click)="onTextboxClicked()" />
									<!-- Loader -->
					
									<div *ngIf="ShowTextLoader==true" class="spinner-container" [ngStyle]="{'right':clearBtnWidth+'px'}">
										<div class="spinner-border" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
					
									<!-- Clear Button -->
									<button *ngIf="search" type="button" class="clear-button" (click)="clearSearch()" #clearBtn>
										<fa-icon [icon]="['fas', 'eraser']" class="search-button-icon "></fa-icon>
										<span clas="clear-button-icon">Clear</span>
									</button>
									<!-- <div class="position-relative d-inline-block ps-3 pt-3"> -->
					
									<!-- </div> -->
								</div>
							</div>
							<div *ngIf="showSearchSuggesion && searchResults.length > 0" class="search-result-section search-custom"
								[ngStyle]="{'width': resultBoxwidth + 'px'}">
								<div>
									<ul class="p-0">
										<li *ngFor="let result of searchResults; let isLast = last" class="search-result-li">
											<div class="d-flex flex-column custom-margin">
												<p class="ml-3 search-title"
													(click)="navigateToArticle(result.article_link,result.category_heading.id)"
													[innerHTML]="highlightMatch(result.article_title, searchForm.get('searchValue')?.value)">
												</p>
												<p class="ml-3 search-author" [ngStyle]="{'font-size': '12px'}"
													[innerHTML]="highlightMatch(formatAuthors(result.authors), searchForm.get('searchValue')?.value)">
												</p>
												<p class="ml-3 search-pub-date">
													<fa-icon [icon]="['fas','calendar-days']" class="icon-date pe-2"></fa-icon>
													<span [innerHTML]="result.published_online"></span>
												</p>
												<div class="ml-3 card-tag">
													<a href="#" class="tag-content badge">{{ result.surgical_speciality }}</a>
													<a href="#" class="tag-content badge">{{ result.content_type }}</a>
												</div>
											</div>
											<!-- <hr *ngIf="!isLast" class="search-result-divider"> -->
										</li>
									</ul>
								</div>
							</div>
							<div *ngIf="showSearchSuggesion && !ShowTextLoader && !isInitialLoad && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length > 3"
								class="search-result-section search-custom" [ngStyle]="{'width': resultBoxwidth + 'px'}">
								<div>
									<ul class="p-0">
										<li class="search-result-li">
											<!-- <div class="d-flex justify-content-center">
												<p class="mt-3">No results found</p>
											</div> -->
											<div
												style="display: flex; flex-direction: column; align-items: center; padding-bottom: 5px;">
												<img src="no_data_1.webp" alt="" class="no-data-found-img">
												<h2 class="card-title text-head-size">No articles found</h2>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<!-- <div class="divider-search"></div>  -->
							<div class=" col-lg-2 col-sm-2 col-xs-2">
								<button type="submit" class="btn btn-primary btn-lg search-button mt-0"
								(click)="searchArticles($event)">
								<fa-icon [icon]="['fas', 'magnifying-glass']" class="search-button-icon-custom"></fa-icon>
								<!-- <span class="d-xs-none d-sm-none d-md-none d-lg-inline custom-search ps-1">Search</span> -->
							</button>
			
							</div>
						</div>
	
						<!-- Close Button -->
						<!-- <button type="button" class="close-button" (click)="load_filter = false">
							<fa-icon [icon]="['fas', 'times']"></fa-icon> 
						</button> -->
						<div class="form-group mb-2 mt-5">
							<h5 class="section-heading">BJS Academy</h5>
	
							<!-- Main category dropdown with select styling -->
							<div class="dropdown">
								<select class="form-select custom-select-scroll" id="mainCategorySelect"
									(change)="onCategoryChange($event)">
									<!-- Option for main categories -->
									<option value="all">All</option>
									<option *ngFor="let category of mainCategories" [value]="category.identifier" >
										{{ category.menu_name }}
									</option>
	
									<!-- Optgroup for scientific surgery and its subcategories -->
									<optgroup label="Scientific Surgery">
										<option *ngFor="let subCategory of subCategories" [value]="subCategory.identifier">
											{{ subCategory.menu_name }}
										</option>
									</optgroup>
								</select>
							</div>
						</div>
	
	
	
	
	
	
						<!-- Surgical Speciality Section -->
						<div class="filter-tag-section" *ngIf="surgicalView && uniqueSurgicalSpecialities.length > 0">
							<h5 class="section-heading">Surgical Speciality</h5>
							<div class="filter-badge">
								<span *ngFor="let speciality of uniqueSurgicalSpecialities" class="filter-tag"
									[class.selected]="isSpecialitySelected(speciality)"
									(click)="onSpecialityChange(speciality)">
									{{ speciality }}
								</span>
							</div>
						</div>
	
						<!-- Content Type Section -->
						<div class="filter-tag-section" *ngIf="contentTypeView && uniqueContentTypes.length > 0">
							<h5 class="section-heading">Content Type</h5>
							<div class="filter-badge">
								<span *ngFor="let type of uniqueContentTypes" class="filter-tag"
									[class.selected]="isContentTypeSelected(type)" (click)="onContentTypeChange(type)">
									{{ type }}
								</span>
							</div>
						</div>
	
						<!-- Clear Filters Button -->
						<button class="clear-filters-btn mt-3 me-4" *ngIf="filter==true" (click)="closeFilter(); searchArticles($event)">Apply
							filters</button>
						<button class="clear-filters-btn mt-3 me-4" *ngIf="filter==true" (click)="clearFilters()">Clear
							filters</button>
							<button class="clear-filters-btn mt-3"  (click)="closeFilter()">Close
			               </button>
					</div>
				</div>
	
				<!-- Backdrop -->
			</div>
		</div>

		<div
			class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 pe-0 ps-xxl-2 ps-xl-2 ps-lg-2 ps-md-1 ps-sm-1 ps-xs-1 d-xs-none">
			<div class="d-flex flex-row justify-content-between align-items-center">

				<!-- Search Button -->
				<button type="submit" class="btn btn-primary btn-lg search-button mt-0"
					(click)="searchArticles($event)">
					<fa-icon [icon]="['fas', 'magnifying-glass']" class="search-button-icon"></fa-icon>
					<span class="d-xs-none d-sm-none d-md-none d-lg-inline custom-search ps-1">Search</span>
				</button>



			</div>

		</div>
		<div class=" col-xs-2 p-0 d-sm-none">


			<button class="btn btn-primary btn-sm search-button mt-0" (click)="toggleFilter()">
				<div class="d-flex justify-content-center">
					<fa-icon [icon]="['fas', 'filter']" class="search-filter-icon"></fa-icon>
				<span class="d-xs-none d-sm-none d-md-none d-lg-inline custom-search ps-1">Filter by</span>
				<span *ngIf="filter_total_count > 0" class="badge bg-secondary position-absolute filter-count">
					{{ filter_total_count }}
				</span>
				</div>
				<div class="divider"></div> <!-- Divider Element -->
				<div class="d-flex justify-content-center">
					<fa-icon [icon]="['fas', 'magnifying-glass']" class="search-button-icon"></fa-icon>
				<span class="d-xs-none d-sm-none d-md-none d-lg-inline custom-search ps-1">Search</span>
				</div>
			</button>


		</div>

	</div>
</form>